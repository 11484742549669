import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageSlider from "../../components/ImageSlider/ImageSlider"

//temp page
const MustangLocation = () => {
  return (
    <Layout language="en" navSpacer>
      <SEO
        lang="en"
        title="Coming Soon | Mustang, Oklahoma"
        description="View the progress of our new practice in Mustang, Oklahoma."
      />

      <div
        className="mb-3"
        style={{
          width: "100%",
          maxWidth: "900px",
          marginTop: "150px",
          marginInline: "auto",
          padding: "0 20px"
        }}>
        <h1 style={{ fontSize: "28px" }} className="has-text-centered mt-3">
          New Practice Coming Soon to Mustang, Oklahoma!
        </h1>

        <h4 className="px-0">February 2024</h4>
        <p className="mb-2" style={{ padding: 0 }}>
          We have some exciting news! Oral Surgery Specialists of Oklahoma is
          expanding our practice to a new location in Mustang, OK. We’re ready
          to provide more access to the same high-quality care to our clients in
          Oklahoma City, Mustang, and the surrounding areas. Because growing
          takes some time, things are still a work in progress. But we’ll update
          this page so you can follow along and watch us build our new location
          from the ground up. We can’t wait to welcome you to our brand-new
          location soon!
        </p>

        <ImageSlider
          useDots
          adaptiveHeight
          images={[
            "OKC/DEV/mustaing-progress-1",
            "OKC/DEV/mustaing-progress-2",
            "OKC/DEV/mustaing-progress-3",
            "OKC/DEV/mustaing-progress-4",
            "OKC/DEV/mustaing-progress-5",
            "OKC/DEV/mustaing-progress-6"
          ]}
        />

        <div
          style={{
            margin: "80px auto 40px auto",
            height: "1px",
            width: "80%"
          }}></div>

        <h4>April 2024</h4>
        <p className="mb-2" style={{ padding: 0 }}>
          Great News! We have laid the foundation and the parking lot for our
          new Mustang Oklahoma Practice. Get ready to witness the frame taking
          shape soon. We're inching closer to serving you the finest in oral
          surgery, dental implants, and wisdom tooth extraction right here in
          Mustang Oklahoma. Stay tuned for more news on this journey!
        </p>

        <ImageSlider
          useDots
          adaptiveHeight
          images={[
            "OKC/DEV/Mustang-Phase-2-Foundation-1",
            "OKC/DEV/Mustang-Phase-2-Foundation-2",
            "OKC/DEV/Mustang-Phase-2-Foundation-3",
            "OKC/DEV/Mustang-Phase-2-Foundation-4"
          ]}
        />
      </div>
    </Layout>
  )
}

export default MustangLocation
