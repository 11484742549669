import React from "react"
import Slider from "react-slick"
import "./ImageSlider.scss"
import { Image } from "../Core"

const ImageSlider = ({ images = [], useDots, useArrows, adaptiveHeight }) => {
  const settings = {
    dots: useDots,
    arrows: useArrows,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: adaptiveHeight
  }

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {images.map((image, i) => (
          <div key={i}>
            <Image
              useAR={false}
              publicId={image}
              responsiveUseBreakpoints="true"
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ImageSlider
